import {Controller} from "stimulus"

class BaseController extends Controller {
  // turn on to show console.log for all controllers, or rewrite for specific
  isLogging = false;

  logger() {
    if (this.isLogging) {
      const { identifier = "undefined" } = this;
      const args = [`${identifier.charAt(0).toUpperCase() + identifier.slice(1)}Controller:`, ...arguments];
      console.log.apply( this, args );
    }
  }
}

export default BaseController