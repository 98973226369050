import CableReady from "cable_ready"
import consumer from "./consumer"

consumer.subscriptions.create("GameChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("GameChannel connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("GameChannel disconnected");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("Received",data);
    if (data.cableReady) CableReady.perform(data.operations);
  }
});
