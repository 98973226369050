import BaseController from "./base_controller";
import StimulusReflex from 'stimulus_reflex'
import Typed from "typed.js";

const SHOWING_CLASS = 'is-showing';
const DEFAULT_LOWEST_AUDIO_VOLUME = 25;

export default class extends BaseController {
  static targets = [
    "ambientAudio",
    "confirmPlay",
    "dialogueForm",
    "dialogueText",
    "dialogueTextHidden",
    "monologueAudio",
    "replayAudio"
  ];

  isLogging = false;
  checkMediaInterval = null;
  dialogueText = null;

  connect() {
    this.logger('connect');
    StimulusReflex.register(this);
    if (this.hasConfirmPlayTarget) { // because audio sources delivery by confirmPlayTarget
      this.setCheckMediaInterval();
    }
  }

  disconnect() {
    this.logger('disconnect');
    clearInterval(this.checkMediaInterval);
    this.checkMediaInterval = null
  }

  setCheckMediaInterval() {
    // this interval should check every second if there is new audio
    this.checkMediaInterval = setInterval(() => {
      // ✅ CASE 1: screen has only ambient audio
      //    => play
      //  if there is no First User Interaction
      //      => show confirmation dialogue

      // ✅ CASE 2: screen has only monologue audio
      //    => play
      //  if there is no First User Interaction
      //      => show confirmation dialogue

      // ✅ CASE 3: screen has playing ambient audio and then appears monologue audio
      //  => decrease ambient audio volume to DEFAULT_LOWEST_AUDIO_VOLUME
      //  => add handler to increase volume after finished of monologue audio
      //  => play monologue audio
      //  => increase ambient audio volume to 100% after finish

      // 1. Ambient Audio Player - audio tag should be places in the top of DOM to prevent interruption of audio 
      // after screen update but the source might be updated by each screen, so we should check it all the time.
      if (this.hasAmbientAudioTarget) {
        const audio = this.ambientAudioTarget;
        const src = this.confirmPlayTarget.dataset.ambientUrl;

        if (audio.dataset.isEventListenersAdded !== 'true') {
          audio.addEventListener('pause', (event) => {
            this.logger('ambient audio - pause event');
            event.target.dataset.isPlaying = 'false';
            this.play_audio()
          });
          audio.dataset.isEventListenersAdded = 'true';
          this.logger('ambient audio - has event listeners');
        } else if (src && audio.src !== src) {
          audio.src = src;
          audio.dataset.isPlayed = 'false';
          audio.dataset.isPlaying = 'false';
          this.playAmbientAudio();
          this.logger('ambient audio - set src', {audioSrc: audio.src, src});
        } else if (!src && audio.dataset.isPlaying === 'true' && !audio.paused) {
          this.logger('ambient audio - stop!');
          audio.src = '';
          audio.pause();
        }
      }

      // 2. Monologue Audio Player
      // Monologue Audio tag comes with each screen and should be played only once
      // it might be refreshed by modal window
      if (this.hasMonologueAudioTarget) {
        const audio = this.monologueAudioTarget;
        const src = this.confirmPlayTarget.dataset.monologueUrl;
        const isModalShowing = this.confirmPlayTarget.dataset.isModalShowing;

        if (audio.dataset.isEventListenersAdded !== 'true') {
          audio.addEventListener('play', () => {
            let volumeLevel = DEFAULT_LOWEST_AUDIO_VOLUME / 100;
            if (this.hasConfirmPlayTarget) {
              const ambientLowestVolume = this.confirmPlayTarget.dataset.ambientLowestVolume;
              const volumeLevelPercentage = ambientLowestVolume ? parseInt(ambientLowestVolume, 10) : DEFAULT_LOWEST_AUDIO_VOLUME;
              volumeLevel = volumeLevelPercentage / 100;
            }
            this.setAmbientAudioVolume(volumeLevel)
          });
          audio.addEventListener('pause', (event) => {
            event.target.dataset.isPlaying = 'false';
            this.setAmbientAudioVolume();
            const {ended} = event.target;
            if (ended) {
              event.target.dataset.isPlayed = 'true';
            }
          });
          audio.dataset.isEventListenersAdded = 'true';
          this.logger('monologue audio - has event listeners');
        } else if (src && audio.src !== src && isModalShowing !== 'true') {
          audio.src = src;
          audio.dataset.isPlaying = 'false';
          audio.dataset.isPlayed = 'false';
          this.playMonologueAudio();
          this.logger('monologue audio - set src', {audioSrc: audio.src, src});
        } else if (!src && audio.dataset.isPlaying === 'true' && !audio.paused) {
          audio.src = '';
          audio.pause();
          this.logger('monologue audio - pause src');
          this.setAmbientAudioVolume()
        }

        // Replay Audio - we need to provide user button which plays audio file one more time
        // This feature used for screen at 10_call_steele script.
        if (this.hasReplayAudioTarget && audio.dataset.isPlayed === 'true') {
          const button = this.replayAudioTarget;
          if (button.dataset.isEventListenersAdded !== 'true') {
            button.addEventListener('click', (event) => {
              event.preventDefault();
              if (this.hasMonologueAudioTarget) {
                this.confirmPlayTarget.dataset.monologueUrl = button.dataset.source;
                audio.dataset.isPlaying = 'false';
                audio.dataset.isPlayed = 'false';
                this.playMonologueAudio();
              }
            });
            button.dataset.isEventListenersAdded = 'true';
          } else {
            this.showReplyAudioButton();
          }
        }
      }


      if (this.hasDialogueTextHiddenTarget) {
        const isTypedText = this.dialogueTextTarget.dataset.isTypedText;
        const hasTypingBeenStarted = this.dialogueTextTarget.dataset.hasTypingBeenStarted;

        if (isTypedText === 'true' && !hasTypingBeenStarted) {
          this.dialogueTextTarget.dataset.hasTypingBeenStarted = 'true';
          this.typeText();
        } else if (isTypedText === 'false') {
          this.showForm();
        }
      }
    }, 100);
  }

  play_audio() {
    this.playAmbientAudio();
    this.playMonologueAudio();
  }

  playAmbientAudio() {
    if (this.hasAmbientAudioTarget) {
      const audio = this.ambientAudioTarget;
      if (audio.src && audio.dataset.isPlaying !== 'true') {
        this.logger('playAmbientAudio');
        audio.muted = false;
        audio
          .play()
          .then(() => {
            this.confirmPlayTarget.classList.remove(SHOWING_CLASS);
            audio.dataset.isPlaying = 'true';
            this.logger('ambient audio - is playing');
          })
          .catch((err) => {
            this.confirmPlayTarget.classList.add(SHOWING_CLASS);
            audio.dataset.isPlaying = 'false';
            this.logger('ambient audio - is NOT playing', err);
          });
      }
    }
  }

  playMonologueAudio() {
    if (this.hasMonologueAudioTarget) {
      const audio = this.monologueAudioTarget;
      if (audio.src && audio.dataset.isPlayed !== 'true') {
        audio.muted = false;
        audio
          .play()
          .then(() => {
            this.confirmPlayTarget.classList.remove(SHOWING_CLASS);
            audio.dataset.isPlaying = 'true';
            this.logger('monologue audio - is playing');
          })
          .catch((err) => {
            this.confirmPlayTarget.classList.add(SHOWING_CLASS);
            audio.dataset.isPlaying = 'false';
            this.logger('monologue audio - is NOT playing', err);
          });
      }
    }
  }

  typeText() {
    this.logger('typeText');
    let startDelay = 0;

    if (this.hasConfirmPlayTarget) {
      startDelay = parseInt(this.confirmPlayTarget.dataset.monologueStartDelay, 10);
    }

    new Typed('#' + this.dialogueTextTarget.id, {
      stringsElement: '#typed-strings',
      typeSpeed: 40, // looks like it's better for voice temp
      showCursor: false,
      startDelay,
      loop: false,
      autoInsertCss: false,
      onBegin: (self) => {
        this.logger('onBegin', self);
        this.dialogueText = this.dialogueTextHiddenTarget.innerHTML;
      },
      onComplete: (self) => {
        this.logger('onComplete', self);
        if (this.hasDialogueTextTarget) {
          this.dialogueTextTarget.dataset.isTypedText = 'false';
        }
        this.showForm();
        if (this.hasDialogueFormTarget) {
          this.stimulate("Play#text_was_typed", this.dialogueFormTarget.dataset.teamKey);
        }
      },
    });
  }

  setAmbientAudioVolume(volume = 1) {
    if (this.hasAmbientAudioTarget) {
      this.logger(`setAmbientAudioVolume to ${volume}`);
      this.ambientAudioTarget.volume = volume;
    }
  }

  showForm() {
    if (this.hasDialogueFormTarget) {
      if (!this.dialogueFormTarget.classList.contains(SHOWING_CLASS)) {
        this.dialogueFormTarget.classList.add(SHOWING_CLASS);
      }
    }
  }

  showReplyAudioButton() {
    if (this.hasReplayAudioTarget) {
      if (!this.replayAudioTarget.classList.contains(SHOWING_CLASS) && this.replayAudioTarget.dataset.isEventListenersAdded === 'true') {
        this.replayAudioTarget.classList.add(SHOWING_CLASS);
      }
    }
  }
}
