import BaseController from "./base_controller";
import StimulusReflex from 'stimulus_reflex'
import {debounce} from 'lodash-es'
import {parseISO} from 'date-fns'

//TODO: Right now you'll always update twice if you initiated it. We can fix this by having a serial # in the update event, or something,
//(or maybe tag the sessionid that's the source of the update?) and not updating if not needed.
export default class extends BaseController {
  connect() {
    this.logger("connect");
    StimulusReflex.register(this);
    this.debouncedUpdate = debounce(this.update, 2000); //wait for up to two seconds after we get an update request, in case we get a storm of them (we will), then do it
    document.addEventListener("TeamUpdated", this.debouncedUpdate);
    this.checkForExpiredPage();
  }

  disconnect() {
    this.logger("disconnect");
    document.removeEventListener("TeamUpdated", this.debouncedUpdate);
  }

  update = (e) => {
    const key = document.getElementById("visibleTeamKeys").getAttribute("data-visible-team-keys");
    this.logger("Received update notification for " + e.detail + " and I have " + key);
    if (e.detail === "*" || key.includes(e.detail)) {
      this.logger("Requesting update");
      const uuid = document.getElementById("updateId").getAttribute("data-update-uuid");
      // TODO: It seems like turning off Update#refresh action will fix issue with double update for Team screen,
      //  so let's check how it works in production with commented code it for a while.
      // this.stimulate("Update#refresh", uuid);
    } else {
      this.logger("Skipping update");
    }
  };

  /* Defeats Chrome's incredibly aggressive caching */
  checkForExpiredPage() {
    let pageExpiresAt = parseISO(this.element.dataset.pageExpiresAt);
    if (pageExpiresAt - new Date() < 0) {
      this.logger("Page expired, refreshing");
      // this.stimulate("Update#refresh", this.data.get("uuid"));
    }
  }
}
